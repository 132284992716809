@import 'product-swatches';
@import 'product-badges';
@import 'product-variations';
@import 'product-quickview';
@import 'product-promotions';

$product-tile-column-base-padding:                calc(#{$gutter / 2});

.product-tile {
  position: relative;

  &__column {
    padding: 0 calc($gutter / 2);
  }

  .tile {
    &-image {
      max-width: 100%;
    }
  }

  & &__brand {
    @include font-type(product-tile-product-brand);
  }

  & &__name {
    @include font-type(product-tile-product-name);
  }

  & &__description {
    @include font-type(product-tile-product-description);
  }

  .price {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    .strike-through {
      font-size: inherit;
      margin: 0;

      @include media-breakpoint-up(md) {
        margin-right: 10px;
      }
    }

    .sales {
      @include font-type(price-sales);
    }
  }
}

// Specific Style for WaardeCheque PDP and PLP
.product-tile,
.product-detail__price {

    .price {
        .range__details {
          .waardecheck {
            &.hidden {
              display: none;
            }
          }
          .waardecheck__msg {
            display: none;
          }
        }
        .range__details--waardecheck {

            .waardecheck {
                @include font-type(price-waardecheck);
                display: inline-block;
                margin-left: 9px;

                .product-detail-page & {
                    font-size: 17px;
                }
            }

            .sales {
                text-decoration: line-through;
            }

            .sales,
            .strike-through {
                @include font-type(default);
                color: $text-color-primary;
            }

            .waardecheck__msg {
                @include font-type(price-sales-waardecheck);
                display: block;

                .product-detail-page & {
                    font-size: 11px;
                }
            }
        }
    }
}

// Compact product tiles, used for Complete the Set
.product-compact {
    border-bottom: 1px solid $styleguide-grey-400;
    padding: 40px 0;

    .product-tile {
        margin: 0 auto;
        max-width: 350px;
    }
}

.product-compact__container {
    margin: 0;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        max-height: 150px;
        overflow: hidden;
    }
}

.product-compact__heading {
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        margin-bottom: 10px;
        text-align: left !important; // overriding Bootstrap
    }
}

.product-compact__item {
    flex: auto;
    justify-content: center;
    padding: 0;

    @include media-breakpoint-up(sm) {
        flex: 0 0 calc(100% / 2 - 20px);
        min-width: 325px;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 calc(100% / 3 - 20px);
    }

    // Hide sibling tiles on mobile, only show the first one
    + .product-compact__item {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
            margin-left: 30px;
        }
    }

    .product-tile {
        display: flex;
    }

    .img-container {
        flex: 0 0 150px;
        margin-right: 15px;

        @include media-breakpoint-up(sm) {
            flex-shrink: 0;
        }
    }

    .badge {
        display: none;
    }

    .product-tile__body {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        padding: 0 !important; // overriding Bootstrap

        @include media-breakpoint-up(sm) {
            align-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .product-tile__name {
            font-weight: 700;
            margin: 0 0 10px 0 !important; // overriding Bootstrap
            order: 1;
            text-align: left;

            // Hide brand name
            .product-tile__brand {
                display: none;
            }

            .link {
                margin: 0 !important; // overriding parent with the same selector
            }
        }

        .product-tile__price {
            order: 3;
            margin: 5px 0 0 !important; // overriding Bootstrap

            @include media-breakpoint-up(sm) {
                order: 4;
            }
        }

        .attributes {
            height: 28px;
            order: 2;
            width: 100%;

            @include media-breakpoint-up(sm) {
                flex-basis: calc(100% - 35px);
                margin-right: 7px;
            }

            .swatch {
                padding: 0 !important; // overriding Bootstrap
            }
        }

        .product-attributes {
            height: 28px;

            &.product-attributes--color {
                display: none;
            }

            .product-tile-variants__size {
                height: 28px;
                line-height: 28px;
                padding: 0 20px 0 5px;
            }
        }

        .cart-and-ipay {
            height: 28px;
            margin-top: 20px !important; // overriding Bootstrap
            order: 4;
            width: 100%;

            @include media-breakpoint-up(sm) {
                margin-top: 0 !important; // overriding Bootstrap
                order: 3;
                width: 28px;
            }

            // Hiding color swatches - out of scope
            + .attributes {
                display: none;
            }
        }

        .add-to-cart-global {
            height: 100%;
            padding: 0;
        }
    }

    .product__addtowishlist {
        display: none;
    }
}
